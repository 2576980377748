import { Grid, Typography, Link as MuiLink, Box, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { createOrder } from '@app/adapter/order-service';
import { getOrganization } from '@app/adapter/organization-service';
import { Confirm } from '@app/components/Order/Confirm';
import { InquiryStepOne } from '@app/components/Order/InquiryStepOne';
import { InquiryStepTwo } from '@app/components/Order/InquiryStepTwo';
import { ProgressStepper } from '@app/components/Order/ProgressStepper';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { getProduct } from '@app/domain/network-actions';
import { InquiryStepTwoState, InquiryStepOneState } from '@app/domain/order';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { Product } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import {
  InquiryStepOneFormData,
  InquiryStepTwoFormData,
  OrderCreation,
  OrderCustomerCreation,
  OrderCustomFields,
  OrderItemCreation,
  OrderTypeValues,
} from '@app/types/order';
import { InquiryType, Organization } from '@app/types/organization';
import { isError } from '@app/utils/error';

enum OrderStep {
  COMPLETE = 'complete',
  CONFIRM = 'confirm',
  STEP1 = 'step1',
  STEP2 = 'step2',
}

export const formatStepTwoData = (data: InquiryStepTwoFormData) => {
  const customForms = [];
  const hiddenKeys = [
    'desiredReservationDate1',
    'desiredReservationDate2',
    'desiredReservationHour1',
    'desiredReservationHour2',
    'desiredReservationMin1',
    'desiredReservationMin2',
  ];
  for (const key in data) {
    if (!hiddenKeys.includes(key)) {
      const answers = [String(data[key])];
      customForms.push({
        answer: answers,
        question: key,
      });
    }
  }

  return customForms;
};

export function ExhibitionRegister(): ReactElement {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const setSnackbar = useSetSnackbar();
  const [step1Data, setSte1pData] = useRecoilState(InquiryStepOneState);
  const [step2Data, setStep2Data] = useRecoilState(InquiryStepTwoState);
  const resetValueStep2Data = useResetRecoilState(InquiryStepTwoState);

  const [step, setStep] = useState<OrderStep>(OrderStep.STEP1);
  const [product, setProduct] = useState<Product | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);

  const fetchProduct = useCallback(async () => {
    if (!productId) return;
    try {
      const result = await getProduct(productId);
      setProduct(result.data);

      if (!result.data.organization) {
        return;
      }
      const organization = result.data.organization as Organization;
      const orgResponse = await getOrganization(organization.parentId);
      setOrganization(orgResponse.data);
      const params = new URLSearchParams();
      params.set('companyName', orgResponse.data.name);
      params.set('productName', result.data.name);
      navigate({ search: params.toString() }, { replace: true });
    } catch (e) {
      if (isError(e)) {
        console.error('fetchProduct', e.message);
      }
      setSnackbar(true, '住宅展示場が見つかりません');
    }
  }, [navigate, productId, setSnackbar]);

  useEffect(() => {
    void fetchProduct();
    // eslint-disable-next-line
  }, []);

  const isLoading = useMemo(() => {
    return loadable === LoadableState.LOADING;
  }, [loadable]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSubmitStep1Form = useCallback(
    (data: InquiryStepOneFormData) => {
      setSte1pData((prevData) => ({
        ...prevData,
        ...data,
      }));
      setStep(OrderStep.STEP2);
      scrollToTop();
    },
    [setSte1pData]
  );

  const handleSubmitStep2Form = useCallback(
    (data: InquiryStepTwoFormData) => {
      // NOTE: 日付は保持し時間だけ上書き
      setStep2Data((prevData) => {
        const desiredReservationDate1 = `${
          data.desiredReservationDate1.split(' ')[0]
        } ${data.desiredReservationHour1}:${data.desiredReservationMin1}`;
        const desiredReservationDate2 = `${
          data.desiredReservationDate2.split(' ')[0]
        } ${data.desiredReservationHour2}:${data.desiredReservationMin2}`;

        return {
          ...prevData,
          ...data,
          desiredReservationDate1,
          desiredReservationDate2,
        };
      });
      setStep(OrderStep.CONFIRM);
      scrollToTop();
    },
    [setStep2Data]
  );

  const handleBackToStep1 = () => {
    setStep(OrderStep.STEP1);
  };

  const handleBackToStep2 = () => {
    setStep(OrderStep.STEP2);
  };

  const handleOrderCreate = useCallback(async () => {
    if (!product) {
      setSnackbar(true, '住宅展示場が見つかりませんでした', 'error');
      setLoadable(LoadableState.HAS_VALUE);
      return;
    }
    setLoadable(LoadableState.LOADING);

    const customer: OrderCustomerCreation = {
      addressLine1: step1Data.customer.addressLine1,
      addressLine2: step1Data.customer.addressLine2,
      addressLine3: step1Data.customer.addressLine3,
      age: step1Data.customer.age,
      email: step1Data.customer.email,
      name: step1Data.customer.name,
      nameKana: step1Data.customer.nameKana,
      phone: step1Data.customer.phone,
    };

    const items: OrderItemCreation[] = product?.variants[0]
      ? [
          {
            productId: productId || '',
            quantity: 1,
            variantId: product?.variants[0]?.id,
          },
        ]
      : [
          {
            productId: productId || '',
            quantity: 1,
          },
        ];

    const customFields: OrderCustomFields = {
      addressLine4: step1Data.customFields.addressLine4,
      addressLine5: step1Data.customFields.addressLine5,
      customForms: formatStepTwoData(step2Data),
      desiredReservationDate: [
        step2Data.desiredReservationDate1,
        step2Data.desiredReservationDate2,
      ],
      orderType: `${OrderTypeValues.EXHIBITION_VISITOR_RESERVE}`,
      postalCode: step1Data.customFields.postalCode,
    };

    const payload: OrderCreation = {
      customFields,
      customer,
      items,
    };

    try {
      await createOrder(product.organizationId, payload);
      setSnackbar(
        true,
        '予約が完了しました。事務局からの返信をお待ち下さい',
        'success'
      );
      setStep(OrderStep.COMPLETE);
      resetValueStep2Data();
      scrollToTop();
      navigate(`/exhibitions/${productId}/inquiry/create/complete`);
    } catch (e) {
      if (isError(e)) {
        console.error('handleOrderCreate', e.message);
      }
      setSnackbar(true, '応募に失敗しました');
    } finally {
      setLoadable(LoadableState.HAS_VALUE);
    }
  }, [
    product,
    step1Data.customer.addressLine1,
    step1Data.customer.addressLine2,
    step1Data.customer.addressLine3,
    step1Data.customer.age,
    step1Data.customer.email,
    step1Data.customer.name,
    step1Data.customer.nameKana,
    step1Data.customer.phone,
    step1Data.customFields.addressLine4,
    step1Data.customFields.addressLine5,
    step1Data.customFields.postalCode,
    productId,
    step2Data,
    setSnackbar,
    resetValueStep2Data,
    navigate,
  ]);

  return (
    <>
      <HeadBlock />
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        sx={{ mt: 10 }}
      >
        {organization && (
          <Typography variant="h3" fontWeight="bold">
            {organization?.name}
          </Typography>
        )}

        <Typography variant="h6" fontWeight="bold">
          ご来場予約
        </Typography>
        <Grid sx={{ my: 3 }}>
          <Typography color="textSecondary">
            ご来場予約は下記のフォームに必要事項をご記入いただき、送信してください。
          </Typography>
          <Typography color="textSecondary">
            追って担当よりご連絡を差し上げます。
          </Typography>
          <Grid sx={{ mb: 3, mt: 6 }}>
            <MuiLink
              component={Link}
              to={`/companies/${product?.organizationId}/inquiry/create?category=${product?.category?.name}&productId=${product?.id}`}
              onClick={resetValueStep2Data}
            >
              資料請求はこちらから
            </MuiLink>
          </Grid>
        </Grid>
        <ProgressStepper
          activeStep={
            step === OrderStep.STEP1
              ? 0
              : step === OrderStep.STEP2
              ? 1
              : step === OrderStep.CONFIRM
              ? 2
              : step === OrderStep.COMPLETE
              ? 3
              : 0
          }
        />
      </Grid>
      <Stack
        sx={{
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: { md: 9, xs: 2 }, py: 4, width: '100%' }}>
          <Box sx={{ p: '0 !important' }}>
            {step === OrderStep.STEP1 && (
              <InquiryStepOne
                onSubmit={handleSubmitStep1Form}
                defaultValues={step1Data}
                isExhibition={true}
                organizationId={product?.organizationId}
              />
            )}
            {step === OrderStep.STEP2 && (
              <InquiryStepTwo
                onSubmit={handleSubmitStep2Form}
                defaultValues={step2Data}
                onBack={handleBackToStep1}
                organizationCustomField={
                  typeof organization?.customFields === 'object'
                    ? organization?.customFields
                    : undefined
                }
                inquiryType={InquiryType.ExhibitionVisitor}
              />
            )}
            {step === OrderStep.CONFIRM && (
              <Confirm
                isExhibition={true}
                onSubmit={handleOrderCreate}
                onBackToStep1={handleBackToStep1}
                onBackToStep2={handleBackToStep2}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Box>
      </Stack>
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </>
  );
}
